import * as React from 'react'
import CalculatorPage from '../components/calculatorPage/calculatorPage'
import InitialValuesProvider from '../components/provider/initialValueProvider'
import Seo from '../components/seo'

const Index = () => {
  return (
    <InitialValuesProvider>
      <CalculatorPage />
    </InitialValuesProvider>
  )
}

export const Head = () => <Seo title="Der Hypothekenrechner" />

export default Index
